import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c1b6f27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rule-msg-box-wrap" }
const _hoisted_2 = { class: "rule-msgbox-title-wrap" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "msgbox-body-wrap" }
const _hoisted_5 = { class: "tab-menu" }
const _hoisted_6 = { class: "content-wrap" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", {
    class: "rule-msg-box-root",
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('setting.gamerules')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon_x)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["tab-item", { active: _ctx.activeTab === 0 }]),
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.activeTab = 0), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.baccarat')), 3),
          _createElementVNode("div", {
            class: _normalizeClass(["tab-item", { active: _ctx.activeTab === 1 }]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.activeTab = 1), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.dragontiger')), 3)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.activeTab === 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mask",
                innerHTML: _ctx.t('rules.baccarat')
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "mask",
                innerHTML: _ctx.t('rules.dragontiger')
              }, null, 8, _hoisted_8))
        ])
      ])
    ])
  ]))
}