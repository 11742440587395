<template>
    <svg
        width="26px"
        height="22px"
        viewBox="0 0 26 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_game_reserve</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game"
                transform="translate(-1365.000000, -24.000000)"
                fill="#4F3A08"
                fill-rule="nonzero"
            >
                <g id="编组-37" transform="translate(1356.333333, 15.000000)">
                    <path
                        d="M20.7708333,30.5625 L9.3125,18.0625 C9.10416667,17.8541667 9,17.6458333 9,17.3333333 L9,15.25 C9,14.9375 9.10416667,14.7291667 9.3125,14.5208333 L14.7291667,9.20833335 C14.9375,9.10416667 15.25,9 15.4583333,9 L27.5416667,9 C27.8541667,9 28.0625,9.10416667 28.2708333,9.3125 L33.6875,14.625 C33.8958333,14.7291667 34,15.0416667 34,15.25 L34,17.3333333 C34,17.5416667 33.8958333,17.8541667 33.7916667,17.9583333 L22.3333333,30.4583333 C22.0208333,30.7708333 21.8125,30.875 21.5,30.875 C21.1875,30.875 20.9791667,30.7708333 20.7708333,30.5625 Z M15.4583333,11.2916667 L11.2916667,15.5625 C11.0833333,15.6666667 11.1875,16.1875 11.1875,16.1875 L11.1875,16.6041667 C11.1875,16.8125 11.1875,16.9166667 11.2916667,17.125 L20.9791667,27.5416667 C21.0833333,27.6458333 21.2916667,27.75 21.5,27.75 C21.7083333,27.75 21.9166667,27.6458333 22.0208333,27.5416667 L31.7083333,17.125 C31.8125,17.0208333 31.9166667,16.8125 31.9166667,16.7083333 L31.9166667,15.5625 C31.9166667,15.4583333 31.8125,15.3541667 31.5,15.0416667 L31.1875,14.8333333 L27.2291667,11.2916667 C27.125,11.1875 26.9166667,11.0833333 26.8125,11.0833333 L15.9791667,11.0833333 C15.7708333,11.0833333 15.5625,11.1875 15.4583333,11.2916667 Z M21.5,23.4791667 L14.5208333,14.3125 L18.5833333,14.3125 L18.375,15.1458333 L21.5,19.4166667 L24.625,15.1458333 L24.3125,14.3125 L28.375,14.3125 L21.5,23.4791667 Z"
                        id="icon_game_reserve"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>