import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "27",
  height: "25",
  viewBox: "0 0 27 25"
}
const _hoisted_2 = {
  id: "icon_menu_costom",
  transform: "translate(1 1)"
}
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Path_209",
        "data-name": "Path 209",
        d: "M3.853,17.222A9.157,9.157,0,0,1,0,10C0,4.477,5.6,0,12.5,0S25,4.477,25,10,19.4,20,12.5,20a15.371,15.371,0,0,1-3.673-.439C7.824,19.315,5,23,5,23l1-4",
        fill: "none",
        stroke: _ctx.fillColor,
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "fill-rule": "evenodd"
      }, null, 8, _hoisted_3),
      _createElementVNode("line", {
        id: "Line_111",
        "data-name": "Line 111",
        x2: "16",
        transform: "translate(4.5 7.5)",
        fill: "none",
        stroke: _ctx.fillColor,
        "stroke-linecap": "round",
        "stroke-width": "2"
      }, null, 8, _hoisted_4),
      _createElementVNode("line", {
        id: "Line_112",
        "data-name": "Line 112",
        x2: "12",
        transform: "translate(4.5 12.5)",
        fill: "none",
        stroke: _ctx.fillColor,
        "stroke-linecap": "round",
        "stroke-width": "2"
      }, null, 8, _hoisted_5)
    ])
  ]))
}