
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { abbreviateNumber } from '@/utils'
import {
    ChipOption,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'

export default defineComponent({
    emits: ['place-bet'],
    props: {
        label: {
            required: false,
            default: '',
            type: String as PropType<string>
        },
        ratio: {
            required: false,
            default: '',
            type: String as PropType<string>
        },
        color: {
            type: Number as PropType<number>,
            required: true
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        tempBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        totalBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        betType: {
            type: Number as PropType<number>,
            required: true
        },
        width: {
            type: Number as PropType<number>,
            required: true
        },
        height: {
            type: Number as PropType<number>,
            required: true
        },
        desk: {
            type: Object as PropType<TableData>,
            required: false
        },
        chipsAttr: {
            type: Object as PropType<{
                width: number
                height: number
                offset: number
            }>,
            default: () => ({ width: 16, height: 16, offset: 5 }),
            required: false
        }
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const chipsUI = ref<
            {
                x: number
                y: number
                amount: number
                class: string
                type: 'temp' | 'confirmed'
            }[]
        >([])

        onMounted(() => {
            emitter.on(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        const betColor = computed(() => {
            const { color } = props

            if (color === 1) return 'red'
            else if (color === 2) return 'green'
            else if (color === 3) return 'blue'
            return ''
        })

        const sumOfTempBetandConfirmedBet = computed(() => {
            const confirmedBet = props.confirmedBet ?? 0
            const tempBet = props.tempBet ?? 0
            return confirmedBet + tempBet
        })

        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        const placeBet = () => {
            emit('place-bet', props.betType)
        }

        const removeTempUIBet = () => {
            chipsUI.value = chipsUI.value.filter((i) => i.type !== 'temp')
        }

        const applyTempUIBet = () => {
            chipsUI.value = chipsUI.value.map((i) => {
                if (i.type === 'temp') i.type = 'confirmed'
                return i
            })

            // console.log(chipsUI.value)
        }

        const handleOnPlaceChip = (arg: {
            t: number
            amt: number
            type: 'temp' | 'confirmed'
            deskId: number
        }) => {
            if (props.desk && props.desk.id !== arg.deskId) return

            if (arg.t !== props.betType) return

            if (
                arg.type === 'confirmed' &&
                chipsUI.value.length === 1 &&
                chipsUI.value[0].amount === arg.amt
            ) {
                return
            } else if (arg.type === 'confirmed') {
                chipsUI.value = []
            }

            const position = generatePosition()

            let _chipIndex = chips.value.value.indexOf(arg.amt)

            if (_chipIndex === -1) _chipIndex = 22

            chipsUI.value.push({
                x: position.x,
                y: position.y,
                amount: arg.amt,
                class: `_chip_${_chipIndex}`,
                type: arg.type
            })
        }

        const generatePosition = () => {
            let postion = { x: 0, y: 0 }

            const maxX =
                props.width -
                (props.chipsAttr.width + props.chipsAttr.offset) +
                props.chipsAttr.offset
            const maxY = props.height - props.chipsAttr.height

            const randomX = Math.floor(Math.random() * maxX)

            const randomY = Math.floor(Math.random() * maxY)

            postion.x = Math.floor(randomX)
            postion.y = Math.floor(randomY)

            return postion
        }

        const resetBetUI = () => {
            chipsUI.value = []
        }

        const chips = computed((): ChipOption => store.getters['chips'])

        return {
            betColor,
            sumOfTempBetandConfirmedBet,
            chipsUI,
            placeBet,
            toShortValue,
            handleOnPlaceChip,
            resetBetUI,
            removeTempUIBet,
            applyTempUIBet
        }
    }
})
