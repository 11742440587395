import { BaseRoadmapItem } from './BaseRoadmapItem'
import { BeadItems } from './BeadItems'
import { BigItem } from './BigItem'

export class RoadMapData {
    beads: BeadItems[] = []
    bigs: BigItem[] = []
    bigeyes: BaseRoadmapItem[] = []
    smalls: BaseRoadmapItem[] = []
    cockroachs: BaseRoadmapItem[] = []
    round = 0
    redCount = 0
    blueCount = 0
    tieCount = 0
    redPairCount = 0
    bluePairCount = 0
    gameType = 1
}
