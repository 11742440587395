
import { defineComponent, onMounted, PropType, ref } from '@vue/runtime-core'

export default defineComponent({
    emits: ['hide'],
    props: {
        status: {
            type: Number as PropType<number>,
            default: -1,
            required: false
        }
    },
    setup(props, { emit }) {
        const show = ref<boolean>(false)

        onMounted(() => {
            show.value = true
            setTimeout(() => {
                show.value = false
                setTimeout(() => {
                    emit('hide')
                }, 300)
            }, 3000)
        })

        const hide = () => {
            show.value = false
            setTimeout(() => {
                emit('hide')
            }, 300)
        }

        return {
            show,
            hide
        }
    }
})
