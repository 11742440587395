import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76c3bc65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "security-msg-box-root" }
const _hoisted_2 = { class: "security-msg-box-wrap" }
const _hoisted_3 = { class: "security-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "security-body-wrap" }
const _hoisted_6 = { class: "input-field" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "input-field" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "input-field" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "error" }
const _hoisted_13 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('setting.changepassword')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon_x)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('changepassword.oldpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oldPassword) = $event))
          }, null, 512), [
            [_vModelText, _ctx.oldPassword]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('changepassword.newpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPassword) = $event))
          }, null, 512), [
            [_vModelText, _ctx.newPassword]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('changepassword.confirmpassword')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmPassword) = $event))
          }, null, 512), [
            [_vModelText, _ctx.confirmPassword]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", {
            class: "btn-confirm",
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.confirm')), 1),
          _createElementVNode("div", {
            class: "btn-cancel",
            onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.cancel')), 1)
        ])
      ])
    ])
  ]))
}