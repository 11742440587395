<template>
    <svg
        width="29px"
        height="46px"
        viewBox="0 0 29 46"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_game_arrow</title>
        <defs>
            <linearGradient
                x1="100%"
                y1="50%"
                x2="1.02418074e-12%"
                y2="50%"
                id="linearGradient-1-icon_game_arrow"
            >
                <stop stop-color="#DEC381" offset="0%"></stop>
                <stop stop-color="#A08243" offset="100%"></stop>
            </linearGradient>
            <path
                d="M1138.6441,661 C1137.89196,661 1137.39054,660.749289 1136.88912,660.247868 C1135.88628,659.245025 1136.13699,657.74076 1137.13983,656.737917 L1153.18532,642.196692 C1153.68674,641.695271 1153.93745,641.193849 1153.93745,640.692428 C1153.93745,640.191006 1153.68674,639.689585 1153.18532,639.188163 L1136.88912,625.399071 C1135.88628,624.396228 1135.63557,622.891963 1136.63841,621.88912 C1137.64125,620.886277 1139.14552,620.635566 1140.14836,621.638409 L1156.19385,635.427502 C1157.69811,636.681055 1158.70096,638.686742 1158.70096,640.692428 C1158.70096,642.698114 1157.94882,644.453089 1156.44456,645.957354 L1140.39907,660.498578 C1139.89765,660.749289 1139.14552,661 1138.6441,661 L1138.6441,661 Z"
                id="path-2-icon_game_arrow"
            ></path>
            <filter
                x="-15.4%"
                y="-8.8%"
                width="148.5%"
                height="127.5%"
                filterUnits="objectBoundingBox"
                id="filter-3-icon_game_arrow"
            >
                <feOffset
                    dx="2"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="1.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game"
                transform="translate(-1131.000000, -620.000000)"
                fill-rule="nonzero"
            >
                <g
                    id="icon_game_arrow"
                    transform="translate(1147.350478, 641.000000) scale(-1, 1) translate(-1147.350478, -641.000000) "
                >
                    <use
                        fill="black"
                        fill-opacity="1"
                        filter="url(#filter-3-icon_game_arrow)"
                        xlink:href="#path-2-icon_game_arrow"
                    ></use>
                    <use
                        fill="url(#linearGradient-1-icon_game_arrow)"
                        xlink:href="#path-2-icon_game_arrow"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
</template>