
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    props: {
        count: {
            required: true,
            type: Number as PropType<number>
        }
    },
    setup(props) {
        const { t } = useI18n()
        const progress = ref<string>('283 283')
        const progressColor = ref<string>('orange')
        const FULL_PROGRESS = 283
        const noAnimate = ref<boolean>(true)

        const TIME_LIMIT = 45

        onMounted(() => {
            //
        })

        const setProgress = () => {
            progress.value = `${(
                (props.count / TIME_LIMIT) *
                FULL_PROGRESS
            ).toFixed(0)} 283`
        }

        const timerColor = computed((): string => {
            if (props.count > 20) return 'green'
            else if (props.count <= 20 && props.count > 10) return 'orange'
            else if (props.count <= 10 && props.count !== -1) return 'red'
            return ''
        })

        watch(
            () => props.count,
            (_val) => {
                setProgress()
                if (_val > 20) {
                    progressColor.value = 'green'
                } else if (_val > 10 && _val <= 20) {
                    progressColor.value = 'orange'
                } else if (_val <= 10) {
                    progressColor.value = 'red'
                }

                if (_val <= 0) {
                    noAnimate.value = false
                    progressColor.value = 'orange'
                }
            },
            { immediate: true }
        )

        return {
            timerColor,
            progress,
            progressColor,
            noAnimate,
            t
        }
    }
})
