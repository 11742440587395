
import { defineComponent, h } from 'vue'

export default defineComponent({
    emits: ['single-click', 'double-click'],
    setup(_, { emit }) {
        let clickCount = 0
        let clickTimer: number | boolean = false
        const delay = 250
        const handleClick = (e: Event) => {
            e.preventDefault()

            clickCount++
            if (clickCount === 1) {
                clickTimer = setTimeout(() => {
                    clickCount = 0
                    emit('single-click')
                }, delay)
            } else if (clickCount === 2) {
                if (typeof clickTimer === 'number') {
                    clearTimeout(clickTimer)
                    clickCount = 0
                    emit('double-click')
                }
            }
        }

        return { handleClick }
    }
})
