import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bb77c56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tip-msg-box-wrap" }
const _hoisted_2 = { class: "tip-msgbox-title-wrap" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "msgbox-body-wrap" }
const _hoisted_5 = { class: "input-capcha-group" }
const _hoisted_6 = { class: "input-field shield-check" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!
  const _component_i_balance = _resolveComponent("i-balance")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tip-msg-box-root",
    onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('game.tip')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon_x)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_i_balance, { class: "icon" }),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tipAmount) = $event)),
              placeholder: _ctx.t('common.amount'),
              onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCapchaKeydown && _ctx.onCapchaKeydown(...args)))
            }, null, 40, _hoisted_7), [
              [_vModelText, _ctx.tipAmount]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "btn-confirm",
            onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.confirm')), 1),
          _createElementVNode("div", {
            class: "btn-cancel",
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.cancel')), 1)
        ])
      ])
    ])
  ]))
}