import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Loby from '@/views/Loby.vue'

export enum ROUTES {
    LOGIN = 'login',
    LOBY = 'loby',
    GAME = 'game',
    SERVER_SELECTION = 'select-server-line'
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: ROUTES.LOBY,
        component: Loby,
        props: (route) => ({ hall: route.query.hall || 'all' })
    },
    {
        path: `/${ROUTES.GAME}`,
        name: ROUTES.GAME,
        props: (_route) => ({
            desk: parseInt(String(_route.query.desk ?? -1))
        }),
        component: () => import('@/views/Game.vue')
    },
    {
        path: `/${ROUTES.LOGIN}`,
        name: ROUTES.LOGIN,
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/select-server-line',
        name: ROUTES.SERVER_SELECTION,
        component: () => import('@/views/ServerSelection.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
