<template>
    <svg
        width="35px"
        height="27px"
        viewBox="0 0 35 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>编组 69</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g id="编组-69" transform="translate(2.355261, 0.603107)">
                <path
                    d="M5.64421149,18.9080153 C6.21036217,19.0368082 6.81085757,19.1659292 7.44569769,19.2953785 C14.0962947,20.6514912 19.4962106,20.7554356 23.6454456,19.6072119 L23.6447387,20.9476819 C23.6447387,22.4207791 22.5752066,23.6759247 21.120773,23.909673 L9.12077299,25.8382444 C7.48491047,26.1011509 5.94565407,24.9881501 5.68274759,23.3522876 C5.65744859,23.1948716 5.6447387,23.0356893 5.6447387,22.8762533 Z M8.6447387,0.0116716376 C8.83021942,0.0116716376 9.01530047,0.0288731069 9.19760431,0.0630550768 L21.1976043,2.31305508 C22.616523,2.57910234 23.6447387,3.81802643 23.6447387,5.26167164 L23.6442552,12.2533229 C17.1114177,13.8538522 11.1377904,13.3010524 5.72337316,10.5949237 L5.72337316,10.5949237 L5.64499123,10.647 L5.6447387,3.01167164 C5.6447387,1.35481739 6.98788445,0.0116716376 8.6447387,0.0116716376 Z"
                    id="形状结合"
                    fill="#5B4C30"
                ></path>
                <path
                    d="M4.13782383,7.23700094 C-1.27092509,10.3241019 -1.27092509,12.9661792 4.13782383,15.1632329 C9.54657276,17.3602866 18.186292,17.3602866 30.0569817,15.1632329"
                    id="路径-2"
                    stroke="#5B4C30"
                    stroke-width="3"
                    transform="translate(15.069122, 12.024012) rotate(1.000000) translate(-15.069122, -12.024012) "
                ></path>
                <polygon
                    id="三角形"
                    fill="#5B4C30"
                    points="31.9517967 12.7768669 28.701792 19.2577876 24.8980607 11.1006594"
                ></polygon>
            </g>
        </g>
    </svg>
</template>