import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/imgs/bet-start.png'
import _imports_1 from '@/assets/imgs/bet-stop.png'


const _hoisted_1 = { class: "bet-start-stop-pop-root" }
const _hoisted_2 = {
  key: 0,
  class: "content-image"
}
const _hoisted_3 = {
  key: 0,
  class: "start",
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  class: "end",
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.status === 1)
                ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                : (_ctx.status === 2)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}