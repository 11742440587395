import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74859d16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-msg-box-root" }
const _hoisted_2 = { class: "message-msg-box-wrap" }
const _hoisted_3 = { class: "message-msgbox-title-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "message-body-wrap" }
const _hoisted_6 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('common.hint')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callBackFunc && _ctx.callBackFunc(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon_x)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "btn-confirm",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callBackFunc && _ctx.callBackFunc(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.confirm')), 1)
        ])
      ])
    ])
  ]))
}