import { fetch } from '@/api'
import { CookiesToken } from '@/types/CookiesToken'
import { getCookieValue, parseBool, setCookieValue } from '@/utils'
import { State, UserInfo, UserState } from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'

const state: UserState = {
    username: getCookieValue(CookiesToken.USER_NAME)?.b64decode(),
    token: getCookieValue(CookiesToken.TOKEN)?.b64decode(),
    lobyNotice: getCookieValue(CookiesToken.LOBY_NOTICE) || '',
    deskNotice: getCookieValue(CookiesToken.DESK_NOTICE) || '',
    userPoint: parseInt(getCookieValue(CookiesToken.POINT) || '0'),
    deskNoticeKey: new Date().getTime(),
    lobyNoticeKey: new Date().getTime(),
    memberid: parseInt(getCookieValue(CookiesToken.MEMBER) || '0'),
    isFreeUser: parseBool(getCookieValue(CookiesToken.FREE_MEMBER) || 'true'),
    commandType: parseInt(getCookieValue(CookiesToken.COMMAND_TYPE) || '2'),
    isGunner: 0,
    userInfo: getCookieValue(CookiesToken.USER_INFO)
        ?.b64decode()
        .toObject<UserInfo>(),
    clientTimeOut: parseInt(
        getCookieValue(CookiesToken.CLIENT_TIME_COUNT) || '5'
    ),
    visitorTable: (getCookieValue(CookiesToken.VISITOR_TABLE) || '[]').toObject<
        []
    >(),
    isBindToGunner: false,
    multitable: (
        getCookieValue(CookiesToken.MULTI_TABLE_DESK) || '[0,0,0,0]'
    ).toObject<[]>()
}

const mutations: MutationTree<UserState> = {
    [AppMutation.INIT_USER_SESSION](
        state,
        payload: {
            token: string
            username: string
            notice: ''
            point: number
            memberId: number
            isFree: boolean
            commandType: number
        }
    ) {
        state.token = payload.token
        state.username = payload.username
        state.userPoint = payload.point
        state.lobyNotice = payload.notice
        state.isFreeUser = payload.isFree
        state.commandType = payload.commandType
        state.memberid = payload.memberId

        if (!state.isFreeUser) {
            let clientTimeOut = 10
            // check ballance if 0 5mins else 15mins
            if (state.userPoint === 0) clientTimeOut = 5

            state.clientTimeOut = clientTimeOut
            setCookieValue(
                CookiesToken.CLIENT_TIME_COUNT,
                String(clientTimeOut)
            )
        } else {
            state.clientTimeOut = 5
            setCookieValue(CookiesToken.CLIENT_TIME_COUNT, '5')
        }

        setCookieValue(CookiesToken.TOKEN, payload.token.b64encode())
        setCookieValue(CookiesToken.USER_NAME, payload.username.b64encode())
        setCookieValue(CookiesToken.LOBY_NOTICE, payload.notice)
        setCookieValue(CookiesToken.POINT, String(payload.point))
        setCookieValue(CookiesToken.FREE_MEMBER, String(payload.isFree))
        setCookieValue(CookiesToken.COMMAND_TYPE, String(payload.commandType))
        setCookieValue(CookiesToken.MEMBER, String(payload.memberId))
    },
    [AppMutation.SET_COIN](state, payload: number) {
        state.userPoint = payload

        setCookieValue(CookiesToken.POINT, String(payload))
    },
    [AppMutation.SET_USER_INFO](state, payload: UserInfo) {
        state.userInfo = payload
        setCookieValue(
            CookiesToken.USER_INFO,
            JSON.stringify(payload).b64encode()
        )
    },
    [AppMutation.SET_LOBY_NOTICE](state, payload: string) {
        state.lobyNotice = payload
        state.lobyNoticeKey = new Date().getTime()
        setCookieValue(CookiesToken.LOBY_NOTICE, payload)
    },
    [AppMutation.SET_DESK_NOTICE](state, payload: string) {
        state.deskNotice = payload
        state.deskNoticeKey = new Date().getTime()

        setCookieValue(CookiesToken.DESK_NOTICE, payload)
    },
    [AppMutation.SET_TOKEN](state, payload) {
        state.token = payload
        setCookieValue(CookiesToken.TOKEN, payload.b64encode())
    },
    [AppMutation.SET_MEMBER_ID](state, payload: number) {
        state.memberid = payload
        setCookieValue(CookiesToken.MEMBER, payload.toString().b64encode())
    },
    [AppMutation.SET_USER](state, payload: string) {
        state.username = payload
        setCookieValue(CookiesToken.USER_NAME, payload.b64encode())
    },
    [AppMutation.SET_IS_FREE](state, payload: boolean) {
        state.isFreeUser = payload
        setCookieValue(CookiesToken.FREE_MEMBER, payload.toString().b64encode())
    },
    [AppMutation.SET_COMMAND_TYPE](state, payload: number) {
        state.commandType = payload
        setCookieValue(CookiesToken.COMMAND_TYPE, payload.toString())
    },
    [AppMutation.SET_IS_GUNNER](state, payload: number) {
        state.isGunner = payload
    },
    [AppMutation.SET_VISITOR_DESK](state, payload: number[]) {
        state.visitorTable = payload
        setCookieValue(CookiesToken.VISITOR_TABLE, JSON.stringify(payload))
    },
    [AppMutation.SET_CLIENT_TIME_OUT](state, payload: number) {
        state.clientTimeOut = payload
        setCookieValue(CookiesToken.CLIENT_TIME_COUNT, String(payload))
    },
    [AppMutation.SET_USER_GUNNER_BIND](state, payload: boolean) {
        state.isBindToGunner = payload
    },
    [AppMutation.SET_MULTI_TABLE](state, payload: number[]) {
        state.multitable = payload
        setCookieValue(CookiesToken.MULTI_TABLE_DESK, JSON.stringify(payload))
    }
}

const actions: ActionTree<UserState, State> = {
    [AppActions.FETCH_DATA]({ ...args }, params): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            fetch(params)
                .then((response) => resolve(response.data))
                .catch((e) => {
                    console.log(e)
                    reject('网络错误')
                })
        })
    }
}

const getters: GetterTree<UserState, State> = {
    token(state) {
        return state.token
    },
    user(state) {
        return state.username
    },
    lobyNotice(state) {
        return state.lobyNotice
    },
    deskNotice(state) {
        return state.deskNotice
    },
    lobyNoticeKey(state) {
        return state.lobyNoticeKey
    },
    deskNoticeKey(state) {
        return state.deskNoticeKey
    },

    coin(state) {
        return state.userPoint
    },
    commandType(state) {
        return state.commandType
    },
    memberid(state) {
        return state.memberid
    },
    isGunner(state) {
        return state.isGunner
    },
    userInfo(state) {
        return state.userInfo
    },
    isFreeUser(state) {
        return state.isFreeUser
    },
    visitorTable(state) {
        return state.visitorTable
    },
    playerTimeOut(state) {
        return state.clientTimeOut
    },
    isBindToGunner(state) {
        return state.isBindToGunner
    },
    multitableDesks(state) {
        return state.multitable
    }
}

export const user: Module<UserState, State> = {
    state,
    mutations,
    actions,
    getters
}
