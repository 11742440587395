import { ref } from 'vue'
import { getImage as fetchImage } from '@/api'

export function useCapchaImage() {
    const capchaSrc = ref<string>()
    const headerToken = ref<string>()
    const isLoadingCapcha = ref<boolean>()

    const getImage = () => {
        isLoadingCapcha.value = true
        fetchImage()
            .then((response) => {
                isLoadingCapcha.value = false
                if (response.status === 200) {
                    headerToken.value = response.headers['access-token']
                    const reader = new window.FileReader()
                    reader.readAsDataURL(response.data)
                    reader.onload = () => {
                        const imageData = reader.result
                        capchaSrc.value = imageData as string
                    }
                }
            })
            .catch((e) => {
                isLoadingCapcha.value = false
                console.log(e)
            })
    }

    return {
        getImage,
        isLoadingCapcha,
        headerToken,
        capchaSrc
    }
}
