<template>
    <svg
        width="27px"
        height="27px"
        viewBox="0 0 27 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_game_tip</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game"
                transform="translate(-1512.000000, -22.000000)"
                fill="#4F3A08"
                fill-rule="nonzero"
            >
                <g id="编组-39" transform="translate(1502.000000, 15.000000)">
                    <g
                        id="icon_game_tip"
                        transform="translate(11.000000, 8.000000)"
                    >
                        <path
                            d="M24.9363517,2.13505311 C24.9363517,3.54206221 21.9441124,6.24351969 21.1937075,6.24351969 C20.4433027,6.24351969 17.4510633,3.54206221 17.4510633,2.13505311 C17.4510633,2.0881528 17.45419,2.04750587 17.4635701,2.00373226 C17.4573167,1.94119852 17.45419,1.87866478 17.45419,1.81300435 C17.45419,0.868744912 18.2171016,0.105833308 19.1582344,0.105833308 C19.9336527,0.105833308 20.590257,0.624863334 20.7934916,1.33774795 C20.8372652,1.48470223 20.9529526,1.59726296 21.0999069,1.63165651 L21.1030336,1.63165651 C21.1624407,1.64416326 21.2281011,1.64416326 21.2875081,1.63165651 C21.2906348,1.63165651 21.2937615,1.63165651 21.2968882,1.62852983 C21.4469692,1.59413627 21.5626566,1.48157554 21.6033035,1.33462126 C21.8096649,0.624863334 22.4631424,0.102706622 23.2385608,0.102706622 C24.1796935,0.102706622 24.9426051,0.865618225 24.9426051,1.80987767 C24.9426051,1.88179147 24.9363517,1.95057858 24.9300984,2.01936569 C24.9332251,2.05688593 24.9363517,2.09440618 24.9363517,2.13505311 L24.9363517,2.13505311 Z"
                            id="路径"
                        ></path>
                        <path
                            d="M23.0485742,8.76933423 C23.4594007,9.93535647 23.6859594,11.1889814 23.6859594,12.4939597 C23.6889802,18.6744816 18.680522,23.6859606 12.5,23.6859606 C6.32249879,23.6829398 1.31706138,18.6744816 1.31706138,12.4969804 C1.31706138,6.31947923 6.32551957,1.31102104 12.5030208,1.31102104 C12.9229096,1.31102104 13.3397777,1.3351873 13.7475834,1.38049905 C14.1372644,1.42279001 14.4755921,1.11769093 14.4755921,0.728009922 C14.4755921,0.39270301 14.2248671,0.111770193 13.892581,0.0755207968 C13.4364427,0.024167486 12.9712421,0 12.5030208,0 C5.59449009,-0.0030195609 0,5.59449131 0,12.5 C0,19.4024903 5.59751087,25 12.5,25 C19.4055099,25 25,19.4024903 25,12.5 C25,11.0379423 24.749275,9.63327817 24.2870952,8.3313207 C24.1934509,8.06851258 23.9457467,7.89632795 23.6678347,7.89632795 C23.2147173,7.89330717 22.897535,8.34038305 23.0485742,8.76933423 Z"
                            id="路径"
                            stroke="#4F3A08"
                        ></path>
                        <path
                            d="M12.369318,4.10826486 C13.0203865,4.10826486 13.548471,4.63595642 13.548471,5.28702486 L13.5490191,5.82364951 C15.2511798,6.29250775 16.5051939,7.85439563 16.5051939,9.70344565 C16.5051939,10.3545141 15.9771095,10.8822056 15.326434,10.8822056 C14.6757584,10.8822056 14.147674,10.3545141 14.147674,9.70344565 C14.147674,8.78479869 13.4003401,8.03746485 12.4816932,8.03746485 C11.5630462,8.03746485 10.8157124,8.78479869 10.8157124,9.70344565 C10.8157124,10.6220926 11.5630462,11.3694264 12.4816932,11.3694264 C12.5286452,11.3694264 12.5749556,11.3721708 12.6204728,11.3775078 C14.6607065,11.5074109 16.2804437,13.2086096 16.2804437,15.2805521 C16.2804437,16.9844497 15.1850352,18.4378631 13.6612778,18.9727634 L13.6604532,19.8458896 C13.6604532,20.496958 13.1327616,21.0246496 12.4816932,21.0246496 C11.8306247,21.0246496 11.3029332,20.4965651 11.3029332,19.8458896 L11.302418,19.0441095 C9.66283287,18.5784193 8.45819236,17.0671859 8.45819236,15.2805521 C8.45819236,14.6298766 8.98588392,14.1017921 9.63695236,14.1017921 C10.2880208,14.1017921 10.8157124,14.6298766 10.8157124,15.2805521 C10.8157124,16.1379036 11.5123595,16.8345507 12.369318,16.8345507 C13.2262766,16.8345507 13.9229237,16.1379036 13.9229237,15.2805521 C13.9229237,14.4239865 13.2262766,13.7273394 12.369318,13.7273394 C12.3261917,13.7273394 12.2836067,13.7250223 12.2416806,13.720506 C10.1341626,13.5952637 8.45819236,11.8410661 8.45819236,9.70344565 C8.45819236,7.93638746 9.60345953,6.43158897 11.1906459,5.89239812 L11.190558,5.28702486 C11.190558,4.63595642 11.7182496,4.10826486 12.369318,4.10826486 Z"
                            id="形状结合"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>