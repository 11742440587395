import { CookiesToken } from '@/types/CookiesToken'
import axios from 'axios'
import { store } from '@/store'
import { getCookieValue } from '.'

const request = axios.create({
    baseURL: process.env.API_URL,
    timeout: 8000
})

request.interceptors.request.use(
    (config) => {
        const line = parseInt(getCookieValue(CookiesToken.LINE) ?? '0')

        if (line > 0) {
            if (line === 1) {
                config.baseURL = `${process.env.API1}`
            } else if (line === 2) {
                config.baseURL = `${process.env.API2}`
            } else if (line === 3) {
                config.baseURL = `${process.env.API3}`
            }
        } else if (line === -1) {
            const customIp = store.getters.serverLineStr as string
            config.baseURL = `${customIp}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default request
