import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { getDeviceOrientation } from '@/utils'
import { computed, ref } from 'vue'

export function useViewPort(_el?: HTMLElement) {
    const store = useStore()
    const currentOrientation = ref<'portrait' | 'landscape' | ''>()

    const initialize = () => {
        currentOrientation.value = getDeviceOrientation()
        document
            .getElementById('viewport')
            ?.setAttribute(
                'content',
                'width=' +
                    window.innerWidth +
                    ', height=' +
                    window.innerHeight +
                    ', initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
            )
    }

    const windowResize = (_elParam?: HTMLElement) => {
        // const _root = document.querySelector(':root') as HTMLElement
        // if (_root) {
        //     const rs = getComputedStyle(_root)

        //     _root.style.setProperty('--max-height', window.innerHeight + 'px')
        //     // _root.style.setProperty('--w-ratio', window.innerWidth + 'px')
        //     _root.style.setProperty(
        //         '--h-ratio',
        //         window.innerWidth * 56.25 + 'px'
        //     )
        //     _root.style.setProperty(
        //         '--max-width',
        //         window.innerWidth * 177.78 + 'px'
        //     )
        // }

        if (
            (currentOrientation.value === 'portrait' &&
                window.innerHeight < window.innerWidth) ||
            (currentOrientation.value !== 'portrait' &&
                window.innerHeight > window.innerWidth)
        ) {
            currentOrientation.value = getDeviceOrientation()
            document
                .getElementById('viewport')
                ?.setAttribute(
                    'content',
                    'width=' +
                        window.innerWidth +
                        ', height=' +
                        window.innerHeight +
                        ', initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                )
        }

        let bodyFonstSize = '0px'
        if (_elParam) {
            bodyFonstSize =
                (_elParam.getBoundingClientRect().width / 20 / 16 / 8) *
                    54.6134 +
                'px'
        } else {
            bodyFonstSize = (window.innerWidth / 20 / 16 / 8) * 54.6134 + 'px'
        }

        document.documentElement.style.setProperty('font-size', bodyFonstSize)
        const _deviceOrientation = getDeviceOrientation()

        if (deviceOrientation.value !== _deviceOrientation) {
            store.commit(AppMutation.SET_ORIENTATION, _deviceOrientation)
        }
    }

    const deviceOrientation = computed(
        (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
    )

    initialize()

    return {
        windowResize,
        getDeviceOrientation
    }
}
