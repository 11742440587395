import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1aff12e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang-msg-box-wrap" }
const _hoisted_2 = { class: "lang-msgbox-title-wrap" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "msgbox-body-wrap" }
const _hoisted_5 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", {
    class: "lang-msg-box-root",
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('dialogs.useragreement.title')), 1),
        _createElementVNode("div", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, [
          _createVNode(_component_icon_x)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.t('dialogs.useragreement.content')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "btn-confirm",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.confirm')), 1),
          _createElementVNode("div", {
            class: "btn-cancel",
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('common.cancel')), 1)
        ])
      ])
    ])
  ]))
}