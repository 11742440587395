import router, { ROUTES } from './'
import { store } from '@/store'
import { UserInfo } from 'vue'

const whiteList = ['/login', '/select-server-line']

router.beforeEach(async (to, from, next) => {
    const token = store.getters['token']
    const user = store.getters['user']
    const userInfo = store.getters['userInfo'] as UserInfo

    if (token && user && userInfo) {
        if (userInfo.firstLogin > 0) {
            if (to.path === whiteList[0]) {
                next({ path: '/' })
            } else {
                next()
            }
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(whiteList[1])
        }
    }
})
