import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b464b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ctrl-bet-box-group-root" }
const _hoisted_2 = {
  key: 0,
  class: "ctrl-bet-box-group"
}
const _hoisted_3 = { class: "bet-view-r" }
const _hoisted_4 = { class: "bet-view-r" }
const _hoisted_5 = {
  key: 1,
  class: "ctrl-bet-box-group"
}
const _hoisted_6 = { class: "bet-view-r" }
const _hoisted_7 = { class: "bet-view-r" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "toast-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ctrl_bet_box = _resolveComponent("ctrl-bet-box")!
  const _component_bet_start_stop_pop = _resolveComponent("bet-start-stop-pop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.gameType === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ctrl_bet_box, {
              class: "bet-view-r-item",
              color: 3,
              label: _ctx.t('game.playerpair'),
              ratio: "1:11",
              totalBet: _ctx.playerPairAmount,
              onPlaceBet: _ctx.placeBet,
              betType: 6,
              tempBet: _ctx.tempPpBet,
              confirmedBet: _ctx.ppBet,
              width: 76,
              height: 65,
              ref: "playerPBox",
              desk: _ctx.mData
            }, null, 8, ["label", "totalBet", "onPlaceBet", "tempBet", "confirmedBet", "desk"]),
            _createVNode(_component_ctrl_bet_box, {
              class: "bet-view-r-item",
              color: 2,
              label: _ctx.t('game.tie'),
              ratio: "1:8",
              totalBet: _ctx.tieTotal,
              onPlaceBet: _ctx.placeBet,
              betType: 2,
              tempBet: _ctx.tempTBet,
              confirmedBet: _ctx.tBet,
              width: 76,
              height: 65,
              ref: "tieBox",
              desk: _ctx.mData
            }, null, 8, ["label", "totalBet", "onPlaceBet", "tempBet", "confirmedBet", "desk"]),
            _createVNode(_component_ctrl_bet_box, {
              class: "bet-view-r-item",
              color: 1,
              label: _ctx.t('game.bankerpair'),
              ratio: "1:11",
              totalBet: _ctx.bankerPairAmount,
              onPlaceBet: _ctx.placeBet,
              betType: 7,
              tempBet: _ctx.tempBpBet,
              confirmedBet: _ctx.bpBet,
              width: 76,
              height: 65,
              ref: "bankerPBox",
              desk: _ctx.mData
            }, null, 8, ["label", "totalBet", "onPlaceBet", "tempBet", "confirmedBet", "desk"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ctrl_bet_box, {
              class: "bet-view-r-item",
              color: 3,
              label: _ctx.t('game.player'),
              ratio: "1:1",
              totalBet: _ctx.playerTotal,
              onPlaceBet: _ctx.placeBet,
              betType: 1,
              tempBet: _ctx.tempPBet,
              confirmedBet: _ctx.pBet,
              width: 114,
              height: 65,
              ref: "playerBox",
              desk: _ctx.mData
            }, null, 8, ["label", "totalBet", "onPlaceBet", "tempBet", "confirmedBet", "desk"]),
            _createVNode(_component_ctrl_bet_box, {
              class: "bet-view-r-item",
              color: 1,
              label: _ctx.t('game.banker'),
              ratio: "1:0.95",
              totalBet: _ctx.bankerTotal,
              onPlaceBet: _ctx.placeBet,
              betType: 0,
              tempBet: _ctx.tempBBet,
              confirmedBet: _ctx.bBet,
              width: 114,
              height: 65,
              ref: "bankerBox",
              desk: _ctx.mData
            }, null, 8, ["label", "totalBet", "onPlaceBet", "tempBet", "confirmedBet", "desk"])
          ])
        ]))
      : (_ctx.gameType === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ctrl_bet_box, {
                class: "bet-view-r-item",
                color: 2,
                label: _ctx.t('game.tie'),
                ratio: "1:8",
                onPlaceBet: _ctx.placeBet,
                tempBet: _ctx.tempTBet,
                totalBet: _ctx.tieTotal,
                confirmedBet: _ctx.tBet,
                betType: 3,
                height: 229,
                width: 65,
                ref: "tieTGBox",
                desk: _ctx.mData
              }, null, 8, ["label", "onPlaceBet", "tempBet", "totalBet", "confirmedBet", "desk"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ctrl_bet_box, {
                class: "bet-view-r-item",
                color: 1,
                label: _ctx.t('game.dragon'),
                ratio: "1:1",
                onPlaceBet: _ctx.placeBet,
                tempBet: _ctx.tempDrBet,
                totalBet: _ctx.bankerTotal,
                confirmedBet: _ctx.drBet,
                betType: 4,
                width: 114,
                height: 65,
                ref: "dragonBox",
                desk: _ctx.mData
              }, null, 8, ["label", "onPlaceBet", "tempBet", "totalBet", "confirmedBet", "desk"]),
              _createVNode(_component_ctrl_bet_box, {
                class: "bet-view-r-item",
                color: 3,
                label: _ctx.t('game.tiger'),
                ratio: "1:1",
                onPlaceBet: _ctx.placeBet,
                tempBet: _ctx.tempTgBet,
                totalBet: _ctx.playerTotal,
                confirmedBet: _ctx.tgBet,
                betType: 5,
                width: 114,
                height: 65,
                ref: "tigerBox",
                desk: _ctx.mData
              }, null, 8, ["label", "onPlaceBet", "tempBet", "totalBet", "confirmedBet", "desk"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8)),
    (_ctx.showStartAndEndNorify)
      ? (_openBlock(), _createBlock(_component_bet_start_stop_pop, {
          key: 3,
          status: _ctx.timerNotifyStatus,
          class: "start-or-stop-game",
          onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showStartAndEndNorify = false))
        }, null, 8, ["status"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_TransitionGroup, {
        class: "toast-container",
        tag: "div",
        name: "list",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastMessage, (toast, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "toast",
              key: index
            }, [
              _createElementVNode("span", null, _toDisplayString(toast.message), 1)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}