import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"])),
    class: "ctrl-btn-click-db-click-root"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}